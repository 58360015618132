<template>
  <ItemWrapper>
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-form autocomplete="off" @submit.prevent="handleSubmit(submitForm)">
        <v-row>
          <v-col cols="12" lg="6">
            <AppCard :loading="pending.getEditClient" :prev-route="prevRoute" :title="$t('Edit Client')">
              <v-container py-0>
                <v-row wrap>
                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation
                      v-model="customer"
                      rules="required"
                      label="Customer ID"
                      name="customer_id"
                      placeholder="Enter Customer ID"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <SelectGender v-model="sex" rules="required" />
                  </v-col>

                  <v-col cols="12">
                    <VTextFieldWithValidation
                      v-model="organisation"
                      label="Organisation"
                      name="organisation"
                      :disabled="isUnique"
                      placeholder="Enter Organisation"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation
                      v-model="firstname"
                      rules="required"
                      label="Firstname"
                      name="firstname"
                      :disabled="isUnique"
                      placeholder="Enter Firstname"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation
                      v-model="lastname"
                      rules="required"
                      label="Lastname"
                      name="lastname"
                      :disabled="isUnique"
                      placeholder="Enter Lastname"
                    />
                  </v-col>

                  <v-col cols="12">
                    <VTextFieldWithValidation
                      v-model="email"
                      rules="required|email"
                      label="E-Mail Address"
                      name="email"
                      placeholder="Enter E-Mail Address"
                    />
                  </v-col>

                  <v-col cols="12" sm="8">
                    <VTextFieldWithValidation
                      v-model="street"
                      rules="required"
                      label="Street"
                      name="street"
                      placeholder="Enter Street"
                    />
                  </v-col>

                  <v-col cols="12" sm="4">
                    <VTextFieldWithValidation
                      v-model="number"
                      rules="required"
                      label="Number"
                      name="number"
                      placeholder="Enter Number"
                    />
                  </v-col>

                  <v-col cols="12" sm="4">
                    <VTextFieldWithValidation
                      v-model="zip"
                      rules="required"
                      label="Zip"
                      name="zip"
                      placeholder="Enter Zip"
                    />
                  </v-col>

                  <v-col cols="12" sm="4">
                    <VTextFieldWithValidation
                      v-model="city"
                      rules="required"
                      label="City"
                      name="city"
                      placeholder="Enter City"
                    />
                  </v-col>

                  <v-col cols="12" sm="4">
                    <VTextFieldWithValidation
                      v-model="country"
                      rules="required"
                      label="Country"
                      name="country"
                      placeholder="Enter Country"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation
                      v-model="phone"
                      rules="required"
                      label="Phone"
                      name="phone"
                      placeholder="Enter Phone"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <VTextFieldWithValidation v-model="fax" label="Fax" name="fax" placeholder="Enter Fax" />
                  </v-col>

                  <v-col cols="12" class="text-right">
                    <v-btn type="submit" class="mx-0 font-weight-light" color="success" :disabled="pending.editClient">
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </AppCard>
          </v-col>

          <v-col cols="12" lg="6">
            <v-row wrap>
              <v-col cols="12">
                <AppCard :loading="pending.getOptions || pending.getEditClient" :title="$t('Billing Options')">
                  <v-row wrap>
                    <v-col v-if="pricelistsArray && pricelistsArray.length >= 1" cols="12" sm="6">
                      <VSelectWithValidation
                        v-model="pricelist_id"
                        label="Pricelist"
                        :items="pricelistsArray"
                        item-value="id"
                        item-text="name"
                        menu-props="offset-y"
                        placeholder="Select Pricelist"
                      />
                    </v-col>

                    <v-col cols="12" sm="6">
                      <VTextFieldWithValidation
                        v-model="vatid"
                        label="VAT ID"
                        name="vatid"
                        placeholder="Enter VAT ID"
                      />
                    </v-col>

                    <v-col cols="12" sm="6">
                      <VSwitchWithValidation
                        v-model="reversecharge"
                        label="Reverse Charge"
                        :false-value="0"
                        :true-value="1"
                      />
                    </v-col>

                    <v-col cols="12" sm="6">
                      <VSelectWithValidation
                        v-model="vatoverride"
                        clearable
                        label="VAT override"
                        :items="taxes"
                        menu-props="offset-y"
                        placeholder="Select Tax"
                      />
                    </v-col>

                    <v-col v-if="paymentTypesArray && paymentTypesArray.length >= 1" cols="12" sm="6">
                      <VSelectWithValidation
                        v-model="paymenttypes"
                        label="Payment Type"
                        clearable
                        multiple
                        :items="paymentTypesArray"
                        menu-props="offset-y"
                        placeholder="Select Payment Type"
                      />
                    </v-col>

                    <v-col v-if="tenantsArray && tenantsArray.length > 1" cols="12" sm="6">
                      <VSelectWithValidation
                        v-model="tenant_id"
                        label="Tenant"
                        :items="tenantsArray"
                        item-value="id"
                        item-text="name"
                        menu-props="offset-y"
                        placeholder="Select Tenant"
                      />
                    </v-col>
                  </v-row>
                </AppCard>
              </v-col>

              <v-col cols="12">
                <AppCard :loading="pending.getEditClient" :title="$t('Handles')">
                  <v-row wrap>
                    <v-col cols="12">
                      <SearchSelectHandle
                        v-model="billing_handle_id"
                        rules="required"
                        handle-type="Billing"
                        :handle="client.billing_handle"
                        persistent-placeholder
                      />
                    </v-col>
                    <v-col cols="12">
                      <SearchSelectHandle
                        v-model="admin_handle_id"
                        rules="required"
                        handle-type="Admin"
                        :handle="client.admin_handle"
                        persistent-placeholder
                      />
                    </v-col>
                    <v-col cols="12">
                      <SearchSelectHandle
                        v-model="contact_handle_id"
                        rules="required"
                        handle-type="Contact"
                        :handle="client.contact_handle"
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>
                </AppCard>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
  </ItemWrapper>
</template>

<script>
import { computed, defineComponent, onUnmounted, ref } from '@vue/composition-api'
import { mapFields } from 'vuex-composition-map-fields'

import { taxes } from '@/config'

import useDirectRedirectToItem from '@/composables/useDirectRedirectToItem'

import AppCard from '@/components/UI/AppCard.vue'
import ItemWrapper from '@/layouts/ItemWrapper.vue'
import SelectGender from '@/components/UI/SelectGender.vue'
import VSelectWithValidation from '@/components/inputs/VSelectWithValidation.vue'
import VSwitchWithValidation from '@/components/inputs/VSwitchWithValidation.vue'
import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation.vue'
import SearchSelectHandle from '@/components/Handles/SearchSelectHandle.vue'
import { parseObjectToArray } from '@/helpers'

export default defineComponent({
  name: 'EditClient',
  components: {
    AppCard,
    ItemWrapper,
    SelectGender,
    VSelectWithValidation,
    VSwitchWithValidation,
    VTextFieldWithValidation,
    SearchSelectHandle,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store, $router } }) {
    const { redirectDirect, setForceRedirect } = useDirectRedirectToItem()
    const prevRoute = ref('/Clients')

    // store
    const pending = computed(_ => $store.state.client.pending)
    const options = computed(_ => $store.state.client.options)
    const client = computed(_ => $store.state.client.client)
    const errors = computed(_ => $store.state.client.errors)
    const isUnique = computed(_ => $store.getters['client/isUnique'])
    const editClient = _ => $store.dispatch('client/editClient')
    const getEditClient = _ => $store.dispatch('client/getEditClient')
    const clearClient = _ => $store.dispatch('client/clearClient')
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    getEditClient()

    const tenantsArray = computed(_ => {
      return (
        options.value &&
        options.value.tenants?.map(tenant => {
          return { ...tenant, id: parseInt(tenant.id) }
        })
      )
    })

    const pricelistsArray = computed(_ => {
      return (
        options.value &&
        options.value.pricelists?.map(pricelist => {
          return { ...pricelist, id: parseInt(pricelist.id) }
        })
      )
    })

    const paymentTypesArray = computed(_ => options.value && parseObjectToArray(options.value.paymenttypes))

    const submitForm = _ => {
      if (pending.value.editClient) {
        return
      }
      if (redirectDirect.value) {
        setForceRedirect(true)
      }

      editClient().then(({ client, errors }) => {
        if (errors) {
          setForceRedirect(false)
          addToast({
            msg: 'Bearbeitung fehlgeschlagen',
            type: 'error',
          })
          return
        }

        addToast({
          msg: 'Kunde erfolgreich bearbeitet',
          type: 'success',
        })

        if (redirectDirect.value) {
          $router.push({
            name: 'clients-item',
            params: { id: client.id },
          })
        } else {
          $router.push({ path: prevRoute.value })
        }
      })
    }

    onUnmounted(_ => {
      clearClient()
    })

    return {
      client,
      prevRoute,
      pending,
      options,
      errors,
      taxes,
      paymentTypesArray,
      tenantsArray,
      pricelistsArray,
      isUnique,
      submitForm,
      ...mapFields('client', [
        'client.contact_handle.city',
        'client.contact_handle.country',
        'client.contact_handle.email',
        'client.contact_handle.fax',
        'client.contact_handle.firstname',
        'client.contact_handle.lastname',
        'client.contact_handle.number',
        'client.contact_handle.organisation',
        'client.contact_handle.phone',
        'client.contact_handle.sex',
        'client.contact_handle.street',
        'client.contact_handle.zip',
        'client.customer',
        'client.tenant_id',
        'client.paymenttypes',
        'client.pricelist_id',
        'client.reversecharge',
        'client.vatoverride',
        'client.vatid',
        'client.contact_handle_id',
        'client.admin_handle_id',
        'client.billing_handle_id',
      ]),
    }
  },
}) //
</script>
